import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
const ButBar = () => {
    return ( 
        <div>
        <IconButton color="default" edge="end">
                <ShoppingBasketIcon />
          </IconButton>
        </div>
     );
}
 
export default ButBar;
import { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {IconButton, FormControlLabel, Grid, Tooltip, Typography, makeStyles} from "@material-ui/core";
import { ControlledSwitchComponent } from "../../components/Switch/controlledSwitchForm.component";
import FormLayout from "../../components/layouts/FormLayout";
import { validateEquals, isEmpty } from "../../utils/proprietaryHooks";
import { ButtonSaveChanges } from "../../components/ButtonForm/ButtonSaveChanges";
import TabComponent from "../../components/TabsComponent/TabComponent";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ControlledInputRoundedForm from "../../components/InputForm/ControlledInputRoundedForm";
import { ArrowIcon } from "../../components/IconsLibrary/ArrowIcon";
import TransactionTimeline from "../../components/TimeLine/transactionTimeLine.component";

//#region Entity's dependencies
import { createCustomForm, modifyCustomForm, getCustomFormById } from "../../actions/customform.action";
import { getTimeLineData } from "../../actions/monithor.action";
import { GET_CUSTOMFORM, GET_CUSTOMFORM_CHANGED } from "../../actions/types";
import { messagesResponseCustomForms } from "../../utils/constants";
//#endregion

/**
 * CustomFormsForm Component ( full view for form to create/modify enterprise )
 *
 * @export Class Component
 * @class CustomFormsForm
 * @extends {Component}
 * @returns Redux connect
 */

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    color: theme.palette.common.black,
    borderBottom: `2px solid ${theme.palette.common.grey}`,
    "&:hover": {
      color: theme.palette.secondary.main,
      opacity: 1,
    },
    "&$selected": {
      color: theme.palette.secondary.main.main,
      fontWeight: "bold",
    },
  },
  selected: {
    color: theme.palette.secondary.main.main,
    fontWeight: "bold",
  },
  indicator: {
    backgroundColor: theme.palette.secondary.main,
  },
  card: {
    margin: "0px 15px",
  },
  tabsItem: {
    textTransform: "none",
  },
  tabs: {
    borderBottom: "1px solid #e8e8e8",
  },
  btnApplications: {
    marginBottom: "9px",
    boxShadow: "0px 3px 3px #0000001A",
    borderRadius: "5px",
    color: "rgba(0, 0, 0, 0.54) !important",
  },
  buttonSave: {
    margin: "3px 0px",
    flex: 1,
    float: "right",
  },
  errorText: {
    padding: "10px 15px",
  },
  categoryButtonFalse: { background: "#FFFFFF 0% 0% no-repeat padding-box" },
  categoryButtonTrue: {
    background: "#6D6E71 0% 0% no-repeat padding-box",
    color: "white !important",
  },
  previousArrow: {
    transform: "rotate(180deg)",
    stroke: theme.palette.text.disabled,
  },
  nextArrow: {
    stroke: theme.palette.secondary.main,
  },
}));

const CustomFormsForm = (props) => {
  const classes = useStyles();
  /* #region  Props */
  const {
    userPrivileges,
    recordId,
    record,
    setRecordId,
    setCardState,
    updateChecked,
    setShowRecordAlert,
    setLoading,
    getRecordResponse,
    recordChanged,
    recordErrorResponse,
    messagesResponse,
    GET_RECORD_CHANGED,
    GET_RECORD,
    modifyRecord,
    createRecord,
    getRecordById,
    entity,
    timeLineIdProperty,
    entityView
  } = props;
  /* #endregion */

  /* #region  States */
  const [tab, setTab] = useState(0);
  const [timeLineData, setTimeLineData] = useState([]);
  const [timeLineLogs, setTimeLineLogs] = useState([]);
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    checked: false,
    back: false,
    cancelEdit: false,
    changeTab: false,
    newTab: 0,
  });
  const [enabledTabsForm, setEnabledTabsForm] = useState(
    recordId === 0
  );
  let initialStateForm = {};
  const setRecord = (rec) => {
    initialStateForm = {... rec};
    if(!recordId){
      Object.keys(initialStateForm).forEach((field)=>{
        initialStateForm[field] = Number.isInteger(initialStateForm[field]) ? 0 : /^\d{4}-\d{1,2}-\d{1,2}$/.test(initialStateForm[field]?.split("T")[0]) && Date.parse(initialStateForm[field]) > 0 ? new Date().toLocaleString().substring(0,19): "";
      });
      initialStateForm.id = recordId;
    } else {
      Object.keys(initialStateForm).forEach((field)=>{
        if(Object.prototype.toString.call(initialStateForm[field]) === "[object String]" && /^\d{4}-\d{1,2}-\d{1,2}$/.test(initialStateForm[field]?.split("T")[0]) && Date.parse(initialStateForm[field]) > 0){
          initialStateForm[field] = initialStateForm[field].substring(0,19);
        }
      });
    }
    return initialStateForm
  };
  const [formRecordData, setFormRecordData] = useState(
    setRecord(record)
  );
  /* #endregion */

  /* #region  useRef */
  const validators = useRef({
    isNewRecord: recordId === 0 ? true : false,
  });
  const originalInfo = useRef({
    originalData: formRecordData,
  });
  /* #endregion */

  //#region react hook form config
  const requiredMessage = "Campo obligatorio.";
  const validationSchema = Yup.object().shape({
    id: Yup.string(),
    documenttypeid: Yup.number().required(requiredMessage),
    name: Yup.string().max(200).required(requiredMessage),
  });
  const {
    handleSubmit,
    errors,
    formState,
    trigger,
    getValues,
    control,
    setValue,
  } = useForm({
    defaultValues: { ...formRecordData },
    resolver: yupResolver(validationSchema),
    shouldUnregister: false,
  });
  const lastTab = 1; //Tab donde se comienza a colocar los hijos (1:N) del actual registro, si no tiene colocar último tab + 1
  //#endregion

  const dispatch = useDispatch();

  const handleChangeTab = async (event, newTab) => {
    event.preventDefault();
    if (validators.current.isNewRecord) {
      await trigger();
      if (!isEmpty(formState.errors)) {
        return;
      }
      setTab(newTab);
    } else if (enabledTabsForm && tab < lastTab) {
      if (!compareObjects(tab)) {
        setTab(newTab);
        showMessageSaveOrUpdate({
          changeTab: false,
          cancelEdit: false,
          back: false,
        });
      } else {
        setTab(newTab);
      }
    } else {
      setTab(newTab);
    }
  };

  const saveRecord = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (validators.current.isNewRecord) {
      await trigger();
      if (isEmpty(formState.errors)) {
        const [recordSaveData] = createObjectSave(true);
        confirmSaveChanges(recordSaveData);
      }
    } else {
      const [recordSaveData] = createObjectSave(true);
      confirmSaveChanges(recordSaveData);
    }
  };

  const confirmSaveChanges = (recordSaveData) => {
    if (validators.current.isNewRecord) {
      createRecord(recordSaveData, updateChecked);
    } else {
      modifyRecord(recordSaveData, updateChecked, false, tab);
    }
  };

  const NotChange = () => {
    dispatch({
      type: GET_RECORD_CHANGED,
      payload: messagesResponse.noChanges,
    });
  };

  const createObjectSave = (includeForm) => {
    let recordSaveData = JSON.parse(
      JSON.stringify(includeForm ? { ...getValues() } : { id: getValues().id })
    );

    return [recordSaveData];
  };

  const compareObjects = (tab) => {
    setLoading(true);
    let response = validateEquals(
      { ...getValues() },
      originalInfo.current.originalData
    );
    setLoading(false);
    return response;
  };

  const backButton = () => {
    if (validators.current.isNewRecord) {
      resetFieldComplete();
    } else if (enabledTabsForm) {
      if (!compareObjects(0)) {
        showMessageSaveOrUpdate({
          back: true,
          newTab: 0,
          changeTab: false,
          cancelEdit: false,
        });
      } else {
        resetFieldComplete();
      }
    } else {
      resetFieldComplete();
    }
  };

  useEffect(() => {
    return () => {
      resetFieldComplete();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(timeLineIdProperty && record){
      async function fetchData() {
        setTimeLineData(await getTimeLineData(record[timeLineIdProperty],"_"+ entityView));
        setTimeLineLogs(await getTimeLineData(record[timeLineIdProperty],"_LOGS"));
      }
      fetchData();
    }
  }, [timeLineIdProperty]);

  useEffect(() => {
    if (!Array.isArray(recordErrorResponse)) {
      setLoading(false);
    }
  }, [setLoading, recordErrorResponse]);

  useEffect(() => {
    if (!Array.isArray(recordChanged)) {
      setCardState(true);
      setShowRecordAlert(true);
      setConfirmInactivate({
        ...confirmInactivate,
        open: false,
        item: "inactivate",
      });
    }

    if (!!recordChanged) {
      if (recordChanged === messagesResponse.createdRecord) {
        resetFieldComplete();
      }

      if (recordChanged === messagesResponse.updatedRecordData) {
        originalInfo.current.originalData = JSON.parse(
          JSON.stringify({ ...getValues() })
        );
        activeValidations();
      }

      if (recordChanged === messagesResponse.updatedRecordConfig) {
        if (!confirmInactivate.changeTab && !confirmInactivate.back && !confirmInactivate.cancelEdit) {
          resetFieldComplete()
        } else {
          
          activeValidations();
        }
      }

      if (recordChanged === messagesResponse.noChanges ) {
        // Cuando no hay cambios
        activeValidations();
      }

      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordChanged]);

  const resetFieldComplete = () => {
    setRecordId(0);
    setTab(0);
    setConfirmInactivate((state) => ({ ...state, newTab: -1 }));
    setCardState(false);
    setFormRecordData(initialStateForm);
    originalInfo.current = {};
    setLoading(false);
    setConfirmInactivate({
      open: false,
    });
    dispatch({
      type: GET_RECORD,
      payload: [],
    });
    dispatch({
      type: GET_RECORD_CHANGED,
      payload: [],
    });
  };

  const showMessageSaveOrUpdate = (props) => {
    setConfirmInactivate({
      ...confirmInactivate,
      ...props,
      open: true,
      message: (
        <div>
          Hiciste modificaciones
          <br />
          ¿Deseas actualizarlas?
        </div>
      ),
      showBtnAccept: true,
      showBtnCancel: true,
    });
  };

  const closeAlertDefault = () => {
    setConfirmInactivate({
      open: false,
      checked: false,
      back: false,
      cancelEdit: false,
      changeTab: false,
      newTab: 0,
    });
  };

  const activeValidations = () => {
    if (confirmInactivate.changeTab) {
      setTab(confirmInactivate.newTab);
    }

    if (confirmInactivate.back) {
      if (tab === 0) {
        resetFieldComplete();
      } else {
        setTab(0);
      }
    }

    if (confirmInactivate.cancelEdit) {
      setEnabledTabsForm(false);
    }
  };

  const handleCancel = () => {
    for (const key in formRecordData) {
      setValue(key, originalInfo.current.originalData[key]);
    }
    activeValidations();
    closeAlertDefault();
  };

  const handleAccept = (e) => {
    saveRecord(e);
    setConfirmInactivate((state) => ({
      ...state,
      open: false,
    }));
  };

  const handleChangeEdit = (response) => {
    if (!response) {
      if (!compareObjects(tab)) {
        showMessageSaveOrUpdate({
          cancelEdit: true,
          newTab: 0,
          changeTab: false,
          back: false,
        });
      } else {
        setEnabledTabsForm(response);
      }
    } else {
      setEnabledTabsForm(response);
    }
  };

  const defineSubHeader = () => {
    let subheader = "";
    if (!validators.current.isNewRecord) {
      subheader = (enabledTabsForm && tab < lastTab ? "Editar " + items[tab].label.toLowerCase() : items[tab].label) ;
    } else {
      subheader = "Nuevo registro | " + items[tab].label;
    }
    return subheader;
  };

  useEffect(() => {
    if(recordId && enabledTabsForm){
      setLoading(true);
      getRecordById(recordId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRecordById, enabledTabsForm]);

  useEffect(() => {
    if (getRecordResponse?.id) {
      let recordRefresh = setRecord(getRecordResponse);
      if(!validateEquals(recordRefresh, originalInfo.current.originalData)){
        setFormRecordData(recordRefresh);
        for (const key in recordRefresh) {
          setValue(key, recordRefresh[key]);
        }
        originalInfo.current.originalData = JSON.parse(
          JSON.stringify({ ...getValues() })
        );
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoading, getRecordResponse]);

  const [items, setItems] = useState([
    {
      label: "Datos",
      tabContent: (
        <>
          {Object.keys(formRecordData).map((field) =>{
              if(field === "createdby" 
                  || field === "createdon"
                  || field === "idbusinessunit"
                  || field === "idowner"
                  || field === "idstate"
                  || field === "modifiedby"
                  || field === "modifiedon"
                  || (field === "id" && !(validators.current.isNewRecord && (entity.includes("rule") || entity.includes("pifdatatype"))))
                  || field === "idNavigation"
              )
                return;

              if(Number.isInteger(formRecordData[field])){
                return (
                  <Grid item lg={5} md={5} sm={10} xs={12} style={{paddingRight:"5px"}}>
                    <ControlledInputRoundedForm
                      id={field}
                      name={field}
                      inputProps={{ maxLength: 20 }}
                      label={field}
                      fullWidth
                      type="number"
                      control={control}
                      pattern={/[^0-9]/g}
                      disabled={!enabledTabsForm}
                    />
                  </Grid>
                );
              } else if(typeof initialStateForm[field] == "boolean") {
                return (
                  <Grid item lg={5} md={5} sm={10} xs={12} style={{paddingLeft:"20px", paddingTop:"20px"}}>
                    <FormControlLabel
                        className={classes.textField}
                        label={field}
                        variant="outlined"
                        margin="dense"
                        labelPlacement="end"
                        control={
                          <ControlledSwitchComponent
                            id={field}
                            name={field}
                            color="primary"
                            isDisabled={!enabledTabsForm}
                            control={control}
                          />
                        }
                      />
                  </Grid>
                );
              } else if(/^\d{4}-\d{1,2}-\d{1,2}$/.test(initialStateForm[field]?.split("T")[0]) && Date.parse(initialStateForm[field]) > 0 /*formRecordData[field] instanceof Date*/) {
                return (
                  <Grid item lg={5} md={5} sm={10} xs={12} style={{paddingRight:"5px"}}>
                    <ControlledInputRoundedForm
                      id={field}
                      name={field}
                      label={field}
                      fullWidth
                      type="datetime-local"
                      control={control}
                      disabled={!enabledTabsForm}
                    />
                  </Grid>
                );
              } else if(initialStateForm[field]?.length >= 100){
                return (
                  <Grid item lg={5} md={5} sm={10} xs={12} style={{paddingRight:"5px"}}>
                    <ControlledInputRoundedForm
                      id={field}
                      name={field}
                      label={field}
                      multiline
                      rows={7}
                      fullWidth
                      control={control}
                      //pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
                      disabled={!enabledTabsForm}
                    />
                  </Grid>
                );
              } else {
                return (
                  <Grid item lg={5} md={5} sm={10} xs={12} style={{paddingRight:"5px"}}>
                    <ControlledInputRoundedForm
                      id={field}
                      name={field}
                      label={field}
                      fullWidth
                      control={control}
                      //pattern={/[^a-zA-Z0-9ÑñÁÉÍÓÚáéíóú\s()><-]/g}
                      disabled={!enabledTabsForm}
                    />
                  </Grid>
                );
              }
            }
          )}
        </>
      ),
    }
  ]);

  useEffect(() => {
    if(!enabledTabsForm && timeLineData && timeLineData.length && !(timeLineData[0].hasOwnProperty('total') && timeLineData[0].total === 0)){
      items.push(
        {
          label: "Estados",
          tabContent: (
            <Grid container item lg={10} md={10} sm={10} xs={12}>
              <TransactionTimeline timeLineData={timeLineData} isFull={true}/>
            </Grid>
          ),
        }
      );

      setItems([...items]);
    }
  }, [timeLineData]);

  useEffect(() => {
    if(!enabledTabsForm && timeLineLogs && timeLineLogs.length && !(timeLineLogs[0].hasOwnProperty('total') && timeLineLogs[0].total === 0)){
      items.push(
        {
          label: "Logs",
          tabContent: (
            <Grid container item lg={10} md={10} sm={10} xs={12}>
              <TransactionTimeline timeLineData={timeLineLogs} isFull={true}/>
            </Grid>
          ),
        }
      );

      setItems([...items]);
    }
  }, [timeLineLogs]);

  if(!validators.current.isNewRecord){
    /*items.push(
      {
        label: "Códigos de respuesta",
        tabContent: (
          <Grid container item lg={10} md={10} sm={10} xs={12}>
            
          </Grid>
        ),
      }
    );*/
  }

  return (
    <div>
      <FormLayout
        handleSubmit={(e) => e.preventDefault()}
        handleIconClick={backButton}
        subheaderBase={formRecordData.id}
        subheader={defineSubHeader()}
        isEditing={!validators.current.isNewRecord && userPrivileges?.modify}
        enabledForm={enabledTabsForm}
        setEnabledForm={handleChangeEdit}
        confirmInactivate={confirmInactivate}
        setConfirmInactivate={setConfirmInactivate}
        handleCancel={handleCancel}
        modalMessage={`¿Desea modificar este registro?`}
        handleAccept={(e) => handleAccept(e)}
      >
        <TabComponent
          aria={"records tabs"}
          handleChangeTab={handleChangeTab}
          tab={tab}
          children={items}
        />
        {!recordId && tab < lastTab - 1 && (
        <Grid container item lg={12} md={12} sm={12} alignItems="center" style={{ paddingLeft: 20 }} direction="row-reverse">
          <Tooltip id="button-next" title={tab===0?"Configuración":""} style={{ float: "right", marginTop: "17px 2px 17px 17px" }}>
            <IconButton onClick={(e) => {
              const newTab = tab + 1;
              handleChangeTab(e, newTab);
            }}>
              <ArrowIcon className={classes.nextArrow} />
            </IconButton>
          </Tooltip>
          <Typography
              color="primary"
              className={classes.subtitle}
              variant="h6"
            >
              Siguiente
          </Typography> 
        </Grid>
        )}  { ((!recordId && !(tab < lastTab - 1)) || (recordId && enabledTabsForm && tab < lastTab)) && userPrivileges?.create && (
        <Grid container item direction="row" lg={12} justify="center">
          <Grid container item style={{ marginTop: "10px", marginBottom: "20px" }} lg={4}>
            <ButtonSaveChanges
              title={!recordId ? "Crear" :"Actualizar Datos"}
              isDisabled={!enabledTabsForm}
              handleClick={(e) => {
                if (validators.current.isNewRecord) {
                  handleSubmit(saveRecord(e));
                } else {
                  setConfirmInactivate({
                    cancelEdit: true
                  });
                  if (!compareObjects(0)) {
                    handleSubmit(saveRecord(e));
                  } else {
                    NotChange();
                  }
                }
              }}
            />
          </Grid>
        </Grid>
        )}
      </FormLayout>
    </div>
  );
};

//#region entities's declarations 
CustomFormsForm.propTypes = {
  createRecord: PropTypes.func.isRequired,
  modifyRecord: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  getRecordResponse: state.customFormsReducer.getCustomFormResponse,
  recordChanged: state.customFormsReducer.getCustomFormChangedResponse,
  recordErrorResponse: state.customFormsReducer.setCustomFormErrorResponse,
  messagesResponse: messagesResponseCustomForms,
  GET_RECORD_CHANGED: GET_CUSTOMFORM_CHANGED,
  GET_RECORD: GET_CUSTOMFORM
});

const mapDispatchToProps = {
  createRecord: createCustomForm,
  modifyRecord: modifyCustomForm,
  getRecordById: getCustomFormById,
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomFormsForm);
//#endregion
import React from 'react'

export const ApplicationsMenuIcon = ({
    isActive
}) => {

    const fill2 = isActive ? '#009bdd' : '#fff';

    const fill = isActive ? '#FFFFFF' : '#838383';
    const stroke = isActive ? '#FFFFFF' : '#838383';

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="Componente_98_132" data-name="Componente 98 – 132" transform="translate(0.5)">
                <rect id="círculo" width="19" height="19" rx="9.5" opacity="1" />
                <rect id="Rectángulo_1311" data-name="Rectángulo 1311" width="19" height="19" rx="1" transform="translate(0 0.5)" fill={fill2} stroke={stroke} />
                <g id="Grupo_3124" data-name="Grupo 3124" transform="translate(2.5 3)">
                    <rect id="Rectángulo_1296" data-name="Rectángulo 1296" width="4" height="4" rx="1" fill={fill} />
                    <rect id="Rectángulo_1297" data-name="Rectángulo 1297" width="4" height="4" rx="1" transform="translate(5)" fill={fill} />
                    <rect id="Rectángulo_1298" data-name="Rectángulo 1298" width="4" height="4" rx="1" transform="translate(10)" fill={fill} />
                    <rect id="Rectángulo_1299" data-name="Rectángulo 1299" width="4" height="4" rx="1" transform="translate(0 5)" fill={fill} />
                    <rect id="Rectángulo_1300" data-name="Rectángulo 1300" width="4" height="4" rx="1" transform="translate(5 5)" fill={fill} />
                    <rect id="Rectángulo_1301" data-name="Rectángulo 1301" width="4" height="4" rx="1" transform="translate(10 5)" fill={fill} />
                    <rect id="Rectángulo_1302" data-name="Rectángulo 1302" width="4" height="4" rx="1" transform="translate(0 10)" fill={fill} />
                    <rect id="Rectángulo_1303" data-name="Rectángulo 1303" width="4" height="4" rx="1" transform="translate(5 10)" fill={fill} />
                    <rect id="Rectángulo_1304" data-name="Rectángulo 1304" width="4" height="4" rx="1" transform="translate(10 10)" fill={fill} />
                </g>
            </g>
        </svg>
    )
}

import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import ViewLayout from "../../components/layouts/ViewLayout";
import CustomTable from "../../components/Table/CustomTable.component";
import { Fab, Grid, makeStyles, Tooltip } from "@material-ui/core";
import NewUserIcon from "../../components/IconsLibrary/NewUserIcon";

//#region Entity's dependencies
import { getCustomForms, modifyCustomForm, deleteCustomForm, setEntity, setCustomFormUrl } from "../../actions/customform.action";
import CustomFormsForm from "./customFormsForm.view";
import { messagesResponseCustomForms } from "../../utils/constants";
import { SET_CUSTOMFORM_ERROR, GET_CUSTOMFORM_CHANGED } from "../../actions/types";
import { keys } from "lodash";
//#endregion

/**
 * CustomForms Component ( full view for list of CustomForms)
 *
 * @export Class Component
 * @class CustomForms
 * @extends {Component}
 * @returns Redux connect
 */
const useStyles = makeStyles((theme) => ({
  newUserIcon: {
    stroke: theme.palette.secondary.main,
  },
  styleFab: {
    boxShadow: "none",
  },
}));

const CustomForms = ({ component: Component, container, ...props }) => {
  
  const [entity, setObjectEntity] = useState("");
  const [refreshLocal, setRefreshLocal] = useState({entity:"", searchInit:""});
  const {
    privileges,
    records,
    recordsCount,
    getRecords,
    modifyRecord,
    deleteRecord,
    recordChanged,
    recordErrorResponse,
    messagesResponse,
    GET_RECORD_CHANGED,
    SET_RECORD_ERROR,
    currentEnterprise,
    Loading,
    isLoading,
    entityDefault,
    onlyContent,
    searchInit,
    fields,
    timeLineIdProperty,
    entityView
  } = props;
  const classes = useStyles();

  useEffect(() => {
    if(entityDefault != refreshLocal.entity){
      refreshLocal.entity = entityDefault;
      refreshLocal.searchInit = searchInit;
      setObjectEntity(entityDefault);
    } else if (searchInit != refreshLocal.searchInit){
      refreshLocal.searchInit = searchInit;
      setRefreshLocal({...refreshLocal});
    }
  }, [entityDefault, searchInit]);

  const pathSplit = "/customforms/";
  if(!entityDefault && window.location.pathname.split(pathSplit).length && entity !== (window.location.pathname.split(pathSplit)[window.location.pathname.split(pathSplit).length - 1] ?? "")){
    setObjectEntity(window.location.pathname.split(pathSplit)[window.location.pathname.split(pathSplit).length - 1] ?? "");
  }

  //#region States
  const [userPrivileges, setUserPrivileges] = useState({
    read: true,
    modify: false,
    create: false,
    delete: false
  })
  const [showRecordAlert, setShowRecordAlert] = useState(false);
  const [recordId, setRecordId] = useState(0);
  const [recordInfo, setRecordInfo] = useState({});
  const dispatch = useDispatch();
  const [confirmInactivate, setConfirmInactivate] = useState({
    open: false,
    item: "",
    name: "",
    checked: false,
    message: "",
  });
  const [isFormShowed, setFormShowed] = useState(false);
  const [option, setOption] = useState(0);
  const [queriesColumns, setQueriesColumns] = useState([]);
  const [pageOffset, setPageOffset] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filters, setFilters] = useState(null);
  const [filtersText, setFiltersText] = useState("");
  const [refreshTable, setRefreshTable] = useState(false);
  const [columns, setColumns] = useState([{
    id: "idstate",
    label: "Estado",
    minWidth: 140,
    haveDropDown: true,
    align: "left",
    component: {
      handleChange: (e, value, id) => handleStateClick(e, value, id),
    },
    tooltipMessage: "registro",
    withIcon: true,
    handleIconClick: (ev, id) => handleDeleteClick(ev, id),
    format: (value) => (value === 1 || value === false ? false : true),
  }]);
  let [isLoadingLocal, setLoading] = useState(false);
  if(Loading !== undefined){
    setLoading = Loading;
  }
  //#endregion

  //#region Effects
  useEffect(() => {
    if (!Array.isArray(recordChanged)) {
      setConfirmInactivate({
        open: true,
        message: recordChanged,
        item: "inactivate",
      });
      dispatch({
        type: GET_RECORD_CHANGED,
        payload: [],
      });
    }
    if (!Array.isArray(recordErrorResponse) && recordErrorResponse !== messagesResponse.notPermission) {
      setConfirmInactivate({
        open: true,
        message: recordErrorResponse,
        showBtnAccept: true,
        textButtonAccept: "Cerrar",
        item: "inactivate",
      });
      dispatch({
        type: SET_RECORD_ERROR,
        payload: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordChanged, dispatch, recordErrorResponse]);

  useEffect(() => {
    if(entity && currentEnterprise?.id){
      if(currentEnterprise.monitoringService){
        setCustomFormUrl(`${currentEnterprise.monitoringService.replace("/api","")}/api/cruddata`);
      }
      setEntity(entity);
      setFilters(null);
      setFiltersText("");
      setPageOffset(0);
      setPageSize(10)
      if(searchInit)
        filteredDataHandle("");
      else
        getRecords(null, 0, 10);
      setRefreshTable(true);
      setLoading(true);
      setFormShowed(false);
      if(privileges)
        setUserPrivileges({
          read: privileges.filter(p => p.includes(`IF_${entity.toUpperCase()}_READ`)).length > 0,
          modify: privileges.filter(p => p.includes(`PIF_${entity.toUpperCase()}_MODIFY`)).length > 0,
          create: privileges.filter(p => p.includes(`PIF_${entity.toUpperCase()}_MODIFY`)).length > 0,
          delete: privileges.filter(p => p.includes(`PIF_${entity.toUpperCase()}_MODIFY`)).length > 0
        });
    }
  }, [getRecords, /*isFormShowed*/, entity, currentEnterprise, refreshLocal]);

  useEffect(() => {
    if(refreshTable){
      setRefreshTable(false);
    }
  }, [refreshTable]);

  useEffect(() => {
    if(isLoadingLocal || isLoading){
      sleep(40000).then(r =>{
        setLoading(false);
      })
    }
  }, [isLoadingLocal, isLoading]);

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  useEffect(() => {
    setLoading(false);
    if (records?.length  /*&& columns.length === 1*/) {
      const newColumns = [];
      const newQueriesColumns = [];
      keys(records[0]).forEach((key)=>{
        if(key !== "createdby" 
            && key !== "createdon"
            && key !== "idbusinessunit"
            && key !== "idowner"
            && key !== "idstate"
            && key !== "modifiedby"
            && key !== "modifiedon"
            && key !== "idNavigation"
        ){
          const queryKey = key.charAt(0).toUpperCase() + key.slice(1);
          newColumns.push({
            id: key,
            label: queryKey,
            minWidth: 40,
            alignHeader: "left",
            haveDropDown: true,
            wordLength: 100,
          });
          let type = typeof(records[0][key]);
          newQueriesColumns.push({
            fieldName: queryKey,
            fieldValue: "",
            comparision: "6",
            groupAction: "9",
            type: type
          });
        }
      });
      newColumns.push({
        id: "action",
        label: "",
        align: "left",
        component: {
          handleChange: (e, value, id) => handleStateClick(e, value, id),
        },
        tooltipMessage: "registro",
        withStateIconByValue: true,
        withDeleteIconAlwaysVisible: true,
        withIcon: userPrivileges.delete,
        handleIconClick: (ev, id) => handleDeleteClick(ev, id),
        format: (value) => (value === 1 || value === false ? false : true),
      });
      setColumns(newColumns);
      setQueriesColumns(newQueriesColumns);
      records.forEach((rec, index)=>{
        rec.id = rec.id ?? index+1;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [records]);
  //#endregion

  //#region custom

  /**
   * Manage the click event for of change state
   * @param {*} e
   * @param {*} id
   * @param {*} name
   */
  const handleStateClick = (e, id, name) => {
    setConfirmInactivate({
      open: true,
      item: id,
      name: name,
      showBtnAccept: true,
      showBtnCancel: true,
      checked: e.target.checked,
      message: `¿Está seguro que desea ${
        e.target.checked ? "activar" : "inactivar"
      }
      ${"este registro"}?`,
    });
  };

  /**
   * Manage the click event of the delete icon
   * @param {*} ev
   * @param {*} id
   */
   const handleDeleteClick = (ev, id) => {
    setConfirmInactivate({
      open: true,
      item: id,
      showBtnAccept: true,
      showBtnCancel: true,
      delete: true,
      message: '¿Deseas eliminar este registro?',
    });
  };

  /**
   * Manage the click event of the confirmation or not of the popup
   */
  const handleConfirmChecked = () => {
    let result = records.filter(
      (item) => item.id === confirmInactivate.item
    )[0];

    if(result){
      let recordToBeModified = {
            ... result
          };
      console.log(confirmInactivate);
      if (confirmInactivate.name === "idstate") {
        recordToBeModified.idstate = confirmInactivate.checked ? 0 : 1;
        modifyRecord(
          recordToBeModified,
          updateChecked,
          true
        );
      } else if (confirmInactivate.delete) {
        recordToBeModified.idstate = 2;
        deleteRecord(
          recordToBeModified,
          updateChecked,
          true
        );
      }
    }
    setConfirmInactivate({
      ...confirmInactivate,
      open: false,
      item: "",
      checked: false,
      message: "",
    });
  };

  /**
   * Get the buttons definations of the custom table
   */
  const getButtons = () => {
    let button = {
      searchBar: {
        cellSize: {
          sm: 7,
          xs: 12,
        },
      },
      searchButton: false,
      advancedSearchButton: true,
      menuOrder: {
        options: [
          {
            label: "Más reciente",
            columns: [
              {
                prop: "id",
                direction: 1
              },
            ],
          },
          {
            label: "Más antiguo",
            columns: [
              {
                prop: "id",
                direction: -1
              },
            ],
          },
        ],
        cellSize: {
          sm: 3,
          xs: 12,
        },
      },
      children: () => {
        return userPrivileges.create && (
          <Grid container item lg={12} md={12} sm={2} xs={2} direction="row-reverse">
              <Tooltip title={`Crear`} aria-label={`Nuevo`} >
                <Fab
                  size="small"
                  className={[classes.fab, classes.styleFab]}
                  onClick={() => {
                    setFormShowed(true);
                    setRecordId(0);
                    setRecordInfo({... records[0]});
                  }}
                >
                  <NewUserIcon />
                </Fab>
              </Tooltip>
          </Grid>
        );
      },
    };

    return button;
  };

  /**
   * if record is updated, this method get records again
   */
  const updateChecked = (record) => {
      getRecords(filters, pageOffset, pageSize);
  };

  /**
   * Manage the click event of the selected cell
   */
  const handleCellClick = (evt, row) => {
    setRecordId(row.id);
    setRecordInfo(row);
    setFormShowed(true);
    setLoading(true);
  };

  /**
   * Manage the click event of change page or rows per page
   */
   const handleChangePageOrRows = (page, rowsPerPage) => {
    setPageOffset(page * rowsPerPage);
    setPageSize(rowsPerPage); 
    getRecords(filters, page * rowsPerPage, rowsPerPage);
    setLoading(true);
  };

  /**
   * Manage the query event
   */
   const filteredDataHandle = (search_, queries_) => {
    setFiltersText(search_);
    //let search = search_ + (searchInit ?? "");
    if((search_ + (searchInit ?? ""))?.length > 0 || (queries_ && queries_.length)){
      let queries = queries_ ?? [];
      let searchFields = "";
      //const currentGroup = search_.split("=").length == 1 ? "Grupo0-10" : "";
      
      if(search_.length && search_.split("=").length > 1){
        setQueries(search_, "", queries);
      } else if(search_.length && fields) {
        
        fields.split(",").forEach(field=>{
          searchFields += field + "*=" + search_ + " OR ";
        });
        setQueries(searchFields, "", queries);
      }
      
      if(searchInit && searchInit.length && searchInit.split("=").length > 1)
        setQueries(searchInit, "Grupo0-10", queries);

      if(queries.length){
        if(search_.length && search_.split("=").length == 1 && !searchFields.length){
          const queryLocal = [];
          queriesColumns.forEach((column)=>{
            column.fieldValue = 
              column.type == "string" ? String(search_.toLowerCase()) 
              : column.type == "number" ? Number(search_.toLowerCase())
              : column.type == "boolean" ? Boolean(search_.toLowerCase()) 
              : search_.toLowerCase();
          });
          queriesColumns.filter(q=>!Number.isNaN(q.fieldValue)).forEach((column)=>{
            queryLocal.push(column);
          });
          queries.forEach((q)=>{
            queryLocal.push(q);
          });
          queries = queryLocal;
        }
        setFilters(queries);
        getRecords(queries, 0, pageSize);
        
      } else {
        queriesColumns.forEach((column)=>{
          column.fieldValue = 
            column.type == "string" ? String(search_.toLowerCase()) 
            : column.type == "number" ? Number(search_.toLowerCase())
            : column.type == "boolean" ? Boolean(search_.toLowerCase()) 
            : search_.toLowerCase();
        });
        setFilters(queriesColumns.filter(q=>!Number.isNaN(q.fieldValue)));
        getRecords(queriesColumns.filter(q=>!Number.isNaN(q.fieldValue)), 0, pageSize);
      }
      setPageOffset(0);
      setLoading(true);
    } else {
      setFilters(null);
      setLoading(true);
      setPageOffset(0);
      getRecords(null, pageOffset, pageSize);
    }
  };

  return (
    <>
    {onlyContent ? (
      <>
        {isFormShowed ? (
          <CustomFormsForm
            userPrivileges={userPrivileges}
            recordId={recordId}
            record={recordInfo}
            setRecordId={setRecordId}
            setCardState={setFormShowed}
            updateChecked={updateChecked}
            setLoading={setLoading}
            setShowRecordAlert={setShowRecordAlert}
            entity={entity}
            timeLineIdProperty={timeLineIdProperty}
            entityView={entityView}
          />
        ) : !refreshTable && (
          <CustomTable
            columns={columns}
            data={records}
            mainParam={"name"}
            buttons={getButtons()}
            havePagination={true}
            handleCellClick={handleCellClick}
            option={option}
            setOption={setOption}
            paramToDisable={"idstate"}
            filteredDataHandle={filteredDataHandle}
            customMaxLength={pageSize - 1}
            recordsCount={recordsCount}
            handleChangePageOrRows={handleChangePageOrRows}
            pageOffset={pageOffset / pageSize}
            pageSize={pageSize}
            filtersText={filtersText}
          />
        )}
      </>
    ) : (
      <ViewLayout
        headerTitle={entity}
        handleAccept={handleConfirmChecked}
        confirmInactivate={confirmInactivate}
        setConfirmInactivate={setConfirmInactivate}
        showAlert={showRecordAlert}
        setShowAlert={setShowRecordAlert}
        isLoading={isLoading || isLoadingLocal}
      >
        {isFormShowed ? (
          <CustomFormsForm
            userPrivileges={userPrivileges}
            recordId={recordId}
            record={recordInfo}
            setRecordId={setRecordId}
            setCardState={setFormShowed}
            updateChecked={updateChecked}
            setLoading={setLoading}
            setShowRecordAlert={setShowRecordAlert}
            entity={entity}
            timeLineIdProperty={timeLineIdProperty}
            entityView={entityView}
          />
        ) : !refreshTable && (
          <CustomTable
            columns={columns}
            data={records}
            mainParam={"name"}
            buttons={getButtons()}
            havePagination={true}
            handleCellClick={handleCellClick}
            option={option}
            setOption={setOption}
            paramToDisable={"idstate"}
            filteredDataHandle={filteredDataHandle}
            customMaxLength={pageSize - 1}
            recordsCount={recordsCount}
            handleChangePageOrRows={handleChangePageOrRows}
            pageOffset={pageOffset / pageSize}
            pageSize={pageSize}
            filtersText={filtersText}
          />
        )}
      </ViewLayout>
    )}
    </>
  );
};

//#endregion

//#region entities's declarations 
CustomForms.propTypes = {
  getRecords: PropTypes.func.isRequired,
  modifyRecord: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  records: state.customFormsReducer.getCustomFormsResponse.records,
  recordsCount: state.customFormsReducer.getCustomFormsResponse.count,
  recordChanged: state.customFormsReducer.getCustomFormChangedResponse,
  recordErrorResponse: state.customFormsReducer.setCustomFormErrorResponse,
  currentEnterprise: state.enterpriseReducer.getEnterpriseByIDResponse,
  messagesResponse: messagesResponseCustomForms,
  GET_RECORD_CHANGED: GET_CUSTOMFORM_CHANGED,
  SET_RECORD_ERROR: SET_CUSTOMFORM_ERROR
});

const mapDispatchToProps = {
  getRecords: getCustomForms,
  modifyRecord: modifyCustomForm,
  deleteRecord: deleteCustomForm
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomForms);

function setQueries(search, currentGroup, queries) {
  const hasAND = search.split(" AND ").length > 1;
  let groupActionBefore = "-1";
  search.split(" AND ").forEach((searchAND) => {
    const hasOR = searchAND.split(" OR ").length > 1;
    const lengthOR = searchAND.split(" OR ").length;
    searchAND.split(" OR ").forEach((searchOR, i) => {
      if (searchOR.split("!=").length > 1) {
        queries.push({
          fieldName: searchOR.split("!=")[0].trim(),
          fieldValue: searchOR.split("!=")[1].trim(),
          comparision: "1",
          groupAction: groupActionBefore,
          type: "string",
          group: currentGroup
        });
      } else if (searchOR.split(">=").length > 1) {
        queries.push({
          fieldName: searchOR.split(">=")[0].trim(),
          fieldValue: searchOR.split(">=")[1].trim(),
          comparision: "3",
          groupAction: groupActionBefore,
          type: "string",
          group: currentGroup
        });
      } else if (searchOR.split("<=").length > 1) {
        queries.push({
          fieldName: searchOR.split("<=")[0].trim(),
          fieldValue: searchOR.split("<=")[1].trim(),
          comparision: "5",
          groupAction: groupActionBefore,
          type: "string",
          group: currentGroup
        });
      } else if (searchOR.split("*=").length > 1) {
        queries.push({
          fieldName: searchOR.split("*=")[0].trim(),
          fieldValue: searchOR.split("*=")[1].trim(),
          comparision: "6",
          groupAction: groupActionBefore,
          type: "string",
          group: currentGroup
        });
      } else if (searchOR.split("^=").length > 1) {
        queries.push({
          fieldName: searchOR.split("^=")[0].trim(),
          fieldValue: searchOR.split("^=")[1].trim(),
          comparision: "7",
          groupAction: groupActionBefore,
          type: "string",
          group: currentGroup
        });
      } else if (searchOR.split("$=").length > 1) {
        queries.push({
          fieldName: searchOR.split("$=")[0].trim(),
          fieldValue: searchOR.split("$=")[1].trim(),
          comparision: "8",
          groupAction: groupActionBefore,
          type: "string",
          group: currentGroup
        });
      } else if (searchOR.split("=").length > 1) {
        queries.push({
          fieldName: searchOR.split("=")[0].trim(),
          fieldValue: searchOR.split("=")[1].trim(),
          comparision: "0",
          groupAction: groupActionBefore,
          type: "string",
          group: currentGroup
        });
      } else if (searchOR.split(">").length > 1) {
        queries.push({
          fieldName: searchOR.split(">")[0].trim(),
          fieldValue: searchOR.split(">")[1].trim(),
          comparision: "2",
          groupAction: groupActionBefore,
          type: "string",
          group: currentGroup
        });
      } else if (searchOR.split("<").length > 1) {
        queries.push({
          fieldName: searchOR.split("<")[0].trim(),
          fieldValue: searchOR.split("<")[1].trim(),
          comparision: "4",
          groupAction: groupActionBefore,
          type: "string",
          group: currentGroup
        });
      }
      groupActionBefore = hasOR && i < (lengthOR - 1) ? "9" : hasAND ? "10" : "-1";
    });
  });
}
//#endregion
import { Grid } from "@material-ui/core";

export function MonitoringDashBoardEmbed(props) {
    const {
        source,
      } = props;
  return (
    <>
      <Grid container style={{
          height: "600px",
          position: "relative",
          overflow: "auto"
        }}>
          <iframe id="generalReportService" title="Servicio de reporte general" src={source} width="100%" height="1200" frameBorder="0"
            style={{
                position: "absolute",
                // left: "-90px",
            }}
            onLoad={(event) => {
              //const { contentWindow } = event.target;
              //const main = contentWindow.document.body.querySelector('.actionBar').style.display="none";
            }}
          ></iframe>
        </Grid>
    </>
  );
}
